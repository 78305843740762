import React, { useState } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import ReactMarkdown from "react-markdown"
import { useUrlSearchParams } from "use-url-search-params"
import Cookies from 'universal-cookie';
import CookiesModul from "../components/cookiesmodul"

import LayoutNoNav from "../components/layout_no_nav"
import SEO from "../components/seo"
import ListingsComponent from "../components/listings"
import logo from "../images/logo.svg"

import waveReverse from "../images/wave_reverse.svg"

function handleClick(e) {
  console.log("Test")

  const currentForm = document.getElementById("agent-form")

  if (currentForm.checkValidity() == true) {
    e.target.disabled = true
    e.target.innerText = "Submitting..."
    currentForm.submit()
  }
}

export const query = graphql`
  query AgencyAgentQuery($id: String!) {
    strapiAgencyAgent(strapiId: { eq: $id }) {
      strapiId
      title
      description
      designation
      social_facebook
      social_twitter
      social_instagram
      social_linkedin
      avatar {
        url
      }
      agent_areas_serviced {
        areaName
      }
      agency {
        id
        title
        slug
        description
        address
        lead_form_url
        listings_url
        all_listings_url
        organisation_id
        agency_type
        image {
          url
        }
      }
    }
  }
`

const Agent = ({ data }) => {
  const [params] = useUrlSearchParams({})
  const cookies = new Cookies();

  const agent = data.strapiAgencyAgent
  const [readMore, setReadMore] = useState(false)
  const linkName = readMore ? "Read Less" : "Read More"

  let descriptionContent
  if (!readMore) {
    descriptionContent = (
      <ReactMarkdown source={agent.description.slice(0, 200)} />
    )
  } else {
    descriptionContent = <ReactMarkdown source={agent.description} />
  }

  let socialFacebook
  let socialTwitter
  let socialInstagram
  let socialLinkedin

  if (agent.social_facebook.length > 1) {
    socialFacebook = (
      <a href={agent.social_facebook} target="_blank">
        <i className="border rounded-full text-orange-500 border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-facebook"></i>
      </a>
    )
  } else {
    socialFacebook = ""
  }

  if (agent.social_twitter.length > 1) {
    socialTwitter = (
      <a href={agent.social_twitter} target="_blank">
        <i className="border rounded-full text-orange-500 border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-twitter"></i>
      </a>
    )
  } else {
    socialTwitter = ""
  }

  if (agent.social_instagram.length > 1) {
    socialInstagram = (
      <a href={agent.social_instagram} target="_blank">
        <i className="border rounded-full text-orange-500 border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-instagram"></i>
      </a>
    )
  } else {
    socialInstagram = ""
  }

  if (agent.social_linkedin.length > 1) {
    socialLinkedin = (
      <a href={agent.social_linkedin} target="_blank">
        <i className="border rounded-full text-orange-500 border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-linkedin"></i>
      </a>
    )
  } else {
    socialLinkedin = ""
  }

  return (
    <LayoutNoNav>
      <SEO title={agent.title} image={agent.avatar.url} />

      {params.thankyou == "true" ? (
        <div className="flex items-center fixed z-50 top-0 left-0 w-full h-full">
          <div class="bg-white shadow sm:rounded-lg mx-auto z-40">
            <div class="px-4 py-5 sm:p-6">
              <div class="sm:flex sm:items-start sm:justify-between">
                <div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Thank you
                  </h3>
                  <div class="mt-2 max-w-xl text-sm text-gray-500">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Recusandae voluptatibus corrupti atque repudiandae nam.
                    </p>
                  </div>
                </div>
                <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                  <a
                    href={`/agent/${agent.slug}`}
                    class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  >
                    Close
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-25 z-30"></div>
        </div>
      ) : (
        ""
      )}

      <div className="md:hidden flex fixed justify-between p-2 bottom-0 border-t border-gray-300 z-50 bg-white w-full">
        <div className="flex">
          <img
            className="object-cover h-10 mx-auto border-2 border-gray-200 rounded-lg"
            src={agent.agency.image.url}
            alt={agent.agency.image.url}
          />
          <p className="inline-block self-center text-sm font-bold ml-2">
            {agent.agency.title}
          </p>
        </div>
        <button
          onClick={() => scrollTo("#agent-form-scroll")}
          className="inline-block self-center text-sm bg-orange-500 hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
        >
          Send Message
        </button>
      </div>

      <div className="bg-gray-100 w-full mt-n5">
        <div className="container mx-auto pt-10 px-5">
          <span className="text-sm text-gray-500">Agencies</span>
          <span className="text-sm text-gray-400"> / </span>
          <Link to={`/agency/${agent.agency.slug}`}>
            <span className="text-sm text-gray-500 underline">
              {agent.agency.title}
            </span>
          </Link>
          <span className="text-sm text-gray-400"> / </span>
          <span className="text-sm text-gray-800 font-medium">
            {agent.title}
          </span>
        </div>

        <div className="container mx-auto bg-white shadow rounded-lg mt-5">
          <div className="flex flex-col md:flex-row p-5">
            <div className="w-full md:w-2/3">
              <div className="flex flex-col md:flex-row">
                <img
                  className="object-cover h-24 w-24 mx-auto shadow-md border-2 border-white rounded-full md:mr-2"
                  src={agent.avatar.url}
                  alt={agent.avatar.url}
                />

                <div className="flex-1 md:ml-5 text-center md:text-left">
                  <h1 className="text-xl md:text-2xl font-bold">
                    {agent.title}
                  </h1>

                  <div class="flex flex-wrap justify-center md:justify-start mt-2">
                    {agent.agent_areas_serviced.map((area, i) => {
                      return (
                        <p className="inline-block p-1 mr-2 mb-2 rounded-lg text-xs text-gray-700 bg-gray-200">
                          {area.areaName}
                        </p>
                      )
                    })}
                  </div>

                  <div className="w-full text-orange-500 mt-3">
                    {socialFacebook}
                    {socialTwitter}
                    {socialInstagram}
                    {socialLinkedin}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <p className="text-gray-500 mt-5">
                  <span className="markdown">{descriptionContent}</span>
                  <a
                    className="text-orange-500 cursor-pointer"
                    onClick={() => {
                      setReadMore(!readMore)
                    }}
                  >
                    {linkName}
                  </a>
                </p>

                <hr className="border-t border-gray-100 my-5" />

                <div className="flex">
                  <img
                    className="object-cover h-16 w-24 border-2 border-white rounded-lg mr-2"
                    src={agent.agency.image.url}
                    alt={agent.agency.image.url}
                  />
                  <div>
                    <p className="font-mediumtext-gray-700 mt-2">
                      {agent.agency.title}
                    </p>
                    <p className="text-xs text-gray-500">
                    {agent.designation != null ? agent.designation.replace(/_/g," ") : " "}
                    </p>
                  </div>
                </div>

                <hr
                  id="agent-form-scroll"
                  className="border-t border-gray-100 my-5"
                />
              </div>
            </div>

            <div className="w-full md:w-1/3 mt-5 md:mt-0 md:ml-5">
              <div className="bg-white md:shadow-md rounded-lg md:p-5">
                <h2 className="font-bold text-xl leading-tight">
                  Connect with us to discuss your property needs
                </h2>

                <form
                  action={agent.agency.lead_form_url}
                  method="POST"
                  id="agent-form"
                >
                  <input
                    name="First Name"
                    type="text"
                    placeholder="First Name"
                    className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                  <input
                    name="Surname"
                    type="text"
                    placeholder="Surname"
                    className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                  <input
                    name="EmailDemoFrom"
                    type="text"
                    placeholder="Email"
                    className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                  <input
                    name="ContactNumber"
                    type="text"
                    placeholder="Contact Number"
                    className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                    inputmode="numeric"
                    pattern="[0-9]{10}"
                  />

                  <div className="inline-block relative w-full">
                    <select
                      name="AssistIn"
                      className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="" disabled selected hidden>
                        Contact me about
                      </option>
                      <option value="Selling my property">
                        Selling my property
                      </option>
                      <option value="A property value assessment">
                        A property value assessment
                      </option>
                      <option value="Renting my property">
                        Renting my property
                      </option>
                      <option value="Buying a property">
                        Buying a property
                      </option>
                    </select>
                    <div className="pointer-events-none h-full absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                      <i className="w-full self-center fas fa-caret-down mt-2"></i>
                    </div>
                  </div>

                  <input
                    name="Suburb"
                    type="text"
                    required
                    className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Suburb"
                  ></input>
                  <input
                    name="Price"
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    type="number"
                    required
                    className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Price"
                  ></input>

                  <input type="text" name="agent" className="hidden" value={agent.title}></input>
                  <input type="text" name="organisation_id" className="hidden" value={agent.agency.organisation_id}></input>
                  <input type="text" name="agency_slug" className="hidden" value={agent.agency.slug}></input>
           
                  <input placeholder="utm_source" type="text" name="utm_source" className="hidden" value={cookies.get('utm_source')}></input>
                  <input placeholder="utm_medium" type="text" name="utm_medium" className="hidden" value={cookies.get('utm_medium')}></input>
                  <input placeholder="utm_campaign" type="text" name="utm_campaign" className="hidden" value={cookies.get('utm_campaign')}></input>
                  <input placeholder="utm_content" type="text" name="utm_content" className="hidden" value={cookies.get('utm_content')}></input>

                  <button
                    onClick={handleClick}
                    type="submit"
                    className="text-sm bg-orange-500 hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg mt-2 focus:outline-none focus:shadow-outline"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto mt-5 px-4">
          <h3 className="font-medium text-xl">Properties on the market</h3>
          <a
            href={agent.agency.all_listings_url}
            className="text-sm text-orange-500 underline"
          >
            View all listings
          </a>
        </div>

        <ListingsComponent listings={agent.agency.listings_url} />

        <img className="w-full mt-24" src={waveReverse} alt="" />
      </div>

      <footer className="container mx-auto">
        <div className="px-4 md:px-10 py-24 text-center">
          <img className="h-4 mx-auto" src={logo} alt="" />
          <p className="w-full text-xs text-gray-400 mt-4">
            Copyright © 2021 Flow Living (PTY) Ltd. All rights reserved. <br />
            <a 
            href="/termsofuse" 
            target="_blank"
            className="underline mx-1"
          >
            Terms of use
          </a>
            <a 
              href="/privacypolicy" 
              target="_blank"
              className="underline mx-1">
              Privacy Policy
            </a>
          </p>
        </div>
      </footer>
      <CookiesModul />
    </LayoutNoNav>
  )
}

export default Agent
